import React, { FC } from "react";

import { LocaleDateOptions, useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";

import { useRouter } from "next/router";


export interface LocaleDateTimeProps {
    date?: Date;
    options?: LocaleDateOptions;
}

export interface LocaleDateProps {
    date?: Date;
}

export interface LocaleDateRangeProps {
    startDate: Date;
    endDate: Date;
    displayOnlySeason?: boolean;
}

export interface LocaleTimeProps {
    date?: Date;
    short?: boolean;
}

export const LocaleDateTime: FC<LocaleDateTimeProps> = (props): any => {
    const {
        date = new Date(),
        options,
    } = props;

    const { localeDate } = useI18nLocaleFormat();

    return localeDate(date, options);
}

export const LocaleDate: FC<LocaleDateProps> = (props): any => LocaleDateTime({
    date: props.date,
    options: { year: 'numeric', month: 'long', day: 'numeric' },
});

export const LocaleTime: FC<LocaleTimeProps> = (props): any => LocaleDateTime({
    date: props.date,
    options: { hour: '2-digit', minute: '2-digit', second: props.short ? undefined : '2-digit' },
});

export const LocaleDateRange: FC<LocaleDateRangeProps> = (props): any => {
    const {
        startDate,
        endDate,
        displayOnlySeason
    } = props;

    const { locale } = useRouter();
    const { localeDate, localeSeason } = useI18nLocaleFormat();

    if (displayOnlySeason) {
        return localeSeason(startDate, "common.dates.seasons");
    }

    let stringData = '';

    if (locale === 'en') {
        stringData = localeDate(startDate, { day: '2-digit', month: 'long' });
        if (startDate.getMonth() === endDate.getMonth()) {
            if (startDate.getDate() !== endDate.getDate()) {
                stringData += `-${localeDate(endDate, { day: '2-digit' })}`;
            }
        } else {
            stringData += ` - ${localeDate(endDate, { day: '2-digit', month: 'long' })}`;
        }
    } else {
        if (startDate.getMonth() === endDate.getMonth()) {
            if (startDate.getDate() !== endDate.getDate()) {
                stringData += `${localeDate(startDate, { day: 'numeric' })}-`;
            }
        } else {
            stringData += `${localeDate(startDate, { day: 'numeric', month: 'long' })} - `;
        }
        stringData += localeDate(endDate, { day: 'numeric', month: 'long' });
    }

    return stringData;
}
