import React, { FC } from "react";

import { useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";


export interface LocaleMessageFromProps {
    value: { ru: string, en: string };
}

export const LocaleMessageFrom: FC<LocaleMessageFromProps> = (props): any => {
    const {
        value,
    } = props;

    if (!value) {
        return null;
    }

    const { localeFrom } = useI18nLocaleFormat();

    return localeFrom(value);
}
