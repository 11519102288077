// noinspection JSUnusedGlobalSymbols

import React, { FC } from 'react';
import { GetServerSideProps } from 'next';
import { CfpEvent } from "../data/srm/models/events.models";
import { SrmApiService } from "../data/srm/srm.api.service";
import Layout from "../components/Layout/Layout";
import HomeContent from "../components/Home/HomeContent/HomeContent";
import { getScope } from "../../environment";
import { useIsBrowser } from "../hooks/useIsBrowser";

export interface HomePageProps {
    cfpEvents: CfpEvent[];
    locale: string;
    host: string | undefined;
}

const Home: FC<HomePageProps> = (props) => {
    const { cfpEvents } = props;
    const isBrowser = useIsBrowser();

    const scope = getScope(props.host);

    return (
        <Layout scope={scope}>
            {
                scope && isBrowser &&
                <HomeContent cfpEvents={cfpEvents}
                             scope={scope}
                />
            }
        </Layout>
    )
}

export default Home;

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (context) => {
    const locale = context.locale || 'ru';

    const srmService = new SrmApiService(true);
    const cfpEvents = (await srmService.getOpenCfp()).data;

    const { req } = context;
    let host: string | undefined;
    if (req) {
        host = req.headers.host;
    }

    return {
        props: {
            locale,
            cfpEvents,
            host
        }
    }
}
